import { Box, Typography } from "@mui/material";

const Home = () => {
    return (
        <Box 
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            bgcolor="primary.main"
            textAlign="center"
            sx={{
                backgroundImage: `url('https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg')`, // You can replace with a relevant image
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <Typography 
                variant="h2" 
                color="secondary" 
                sx={{ fontSize: { xs: '2rem', sm: '3rem', md: '4rem' } }} // Responsive font size
            >
                COMING SOON
            </Typography>
            <Typography 
                variant="h6" 
                color="textSecondary" 
                mt={2}
                sx={{ maxWidth: '600px', fontSize: { xs: '1rem', sm: '1.25rem' } }}
            >
                We are working hard to bring something amazing to you. Stay tuned!
            </Typography>
        </Box>
    );
}

export default Home;
